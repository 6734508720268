import { AxiosResponse } from "axios";
import Axios from "./axios";

export interface StoreContactMessageInput {
    phone_number: string;
    message: string;
    captcha: string;
    can_contact_on_whatsapp: boolean;
    user_id?: string;
}

class ContactMessage {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    store(input: StoreContactMessageInput): Promise<AxiosResponse> {
        return this.axios.post("/contact-messages", input);
    }

    adminUpdate(id: string, status: string): Promise<AxiosResponse> {
        return this.axios.patch(`/admin/contact-messages/${id}`, {
            status: status,
        });
    }

    adminIndex(): Promise<AxiosResponse> {
        return this.axios.get("/admin/contact-messages");
    }
}

export default new ContactMessage(Axios);
